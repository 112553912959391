import React from "react";
import { Link } from "react-router-dom";

function Portfolio() {
  return (
    <div className="portfolio">
      <h2>Portfolio</h2>
      <div className="portfolio-grid">
        <Link to="/portfolio/pune" className="portfolio-box">
          <div className="image-overlay">
            <img src="https://picsum.photos/id/1/250/250" alt="1" />
            <div className="overlay-text">Landing page</div>
          </div>
          <h4>FIS Pune</h4>
        </Link>
        <Link to="/portfolio/thane" className="portfolio-box">
          <div className="image-overlay">
            <img src="https://picsum.photos/id/2/250/250" alt="2" />
            <div className="overlay-text">Landing page</div>
          </div>
          <h4>FIS Thane</h4>
        </Link>
        <Link to="/portfolio/sobo" className="portfolio-box">
          <div className="image-overlay">
            <img src="https://picsum.photos/id/3/250/250" alt="3" />
            <div className="overlay-text">Landing page</div>
          </div>
          <h4>FIS Sobo</h4>
        </Link>
        <Link to="/portfolio/edelytics" className="portfolio-box">
          <div className="image-overlay">
            <img src="https://picsum.photos/id/4/250/250" alt="4" />
            <div className="overlay-text">Ecommerce site</div>
          </div>
          <h4>Edelytics</h4>
        </Link>
        <a href="https://www.niyogin.com/lending-business" className="portfolio-box" target="_blank" rel="noreferrer">
          <div className="image-overlay">
            <img src="https://picsum.photos/id/5/250/250" alt="5" />
            <div className="overlay-text">Niyogin Lending Page</div>
          </div>
          <h4>Niyogin</h4>
        </a>
      </div>
    </div>
  );
}

export default Portfolio;
