import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function PortfolioDetail() {
  const { project } = useParams();

  let websiteUrl;
  switch (project) {
    case 'thane':
      websiteUrl = 'https://finland-international-school.pages.dev/';
      break;
    case 'pune':
      websiteUrl = 'https://fis-pune.com';
      break;
    case 'sobo':
      websiteUrl = 'https://admissions.fissobo.com';
      break;
    case 'edelytics':
      websiteUrl = 'https://edelyticsonline.com';
      break;
    case 'niyogin':
      websiteUrl = 'https://www.niyogin.com/lending-business';
      break;
    default:
      websiteUrl = '';
  }

  useEffect(() => {
    // Hide body scrollbar
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div>
      {/* <h2>{`Portfolio Details - ${project}`}</h2> */}
      <iframe title="Website" src={websiteUrl} style={{ width: '100%', height: '100vh', border: 'none' }} />
    </div>
  );
}

export default PortfolioDetail;
