import React, { useEffect, useState } from "react";

// import  FIS_SOBO_1 from '../assets/images/FIS SOBO - Static -01.webp'
// import  FIS_SOBO_3 from '../assets/images/FIS SOBO - Static -03.webp'
// import FIS_SOBO_Carousel from  '../assets/images/FIS SOBO - Carousel-01.webp'

// import National_Cancer_Awareness_01 from '../assets/images/National Cancer Awareness Post-01.webp'
// import National_Cancer_Awareness_02 from '../assets/images/National Cancer Awareness Post-03.webp'
// import IGF_Disaster_Reliefe2 from '../assets/images/IGF Disaster Reliefe2-03.webp'
import Home_Page_EO_Website from "../assets/images/Home Page - EO Website - Tabs-01-01.webp";
import Home_Page_SOBO_Website from "../assets/images/Home Page - SOBO Website-01-01.webp";
import FIS_Finland_Landing_Page from "../assets/images/FIS Finland Landing Page 2-01.webp";
import impact_Guru from "../assets/images/1-01.webp";
import FIS_01 from "../assets/images/2-01.webp";
import FIS_SOBO_Carousel from "../assets/images/3-01.webp";

export default function OurWork({ scrollY, lastScrollY }) {
  const [ourworkCss, setOurworkCss] = useState({
    textUPandDown: 0,
    textUpeerDownHeight: 50,
    ImageScroll: 0,
  });
console.log("ourworkCss", document.documentElement.scrollTop);
  useEffect(() => {
    setOurworkCss((prevFirst) => {
      console.log("scrollY > lastScrollY", scrollY > lastScrollY);
      if (scrollY > lastScrollY) {
        // Check if state needs to be updated
        // if (prevFirst.text_opacity + 0.03 <= 1.0) {
        return {
          ...prevFirst,
          textUPandDown:
            document.documentElement.scrollTop >= 5100
              ? ourworkCss.textUPandDown >= 50
                ? 50
                : prevFirst.textUPandDown + 1
              : 0,
          textUpeerDownHeight:
            document.documentElement.scrollTop >= 5100
              ? ourworkCss.textUpeerDownHeight <= 0
                ? 0
                : prevFirst.textUpeerDownHeight - 1
              : 50,
          ImageScroll:
            document.documentElement.scrollTop >= 4200
              ? ourworkCss.ImageScroll >= 2950
                ? 2950
                : prevFirst.ImageScroll + 3.0
              : ourworkCss.ImageScroll,
        };
        // }
      } else {
        // Check if state needs to be updated
        // if (prevFirst.text_opacity - 0.03 >= 0.0) {
        return {
          ...prevFirst,
          textUPandDown:
            document.documentElement.scrollTop <= 7400
              ? ourworkCss.textUPandDown <= 0
                ? 0
                : prevFirst.textUPandDown - 1
              : ourworkCss.textUPandDown,
          textUpeerDownHeight:
            document.documentElement.scrollTop <= 7400
              ? ourworkCss.textUpeerDownHeight >= 50
                ? 50
                : prevFirst.textUpeerDownHeight + 1
              : ourworkCss.textUpeerDownHeight,
          ImageScroll:
            document.documentElement.scrollTop <= 7800
              ? prevFirst.ImageScroll > 0 && prevFirst.ImageScroll - 15
              : ourworkCss.ImageScroll,
        };
        // }
      }
      // If state doesn't need to be updated, return the previous state
      // return prevFirst;
    });
  }, [scrollY]);
  //   useEffect(() => {
  //     setOurworkCss((prevFirst) => {
  //         if (scrollY > lastScrollY) {
  //           // Check if state needs to be updated
  //           // if (prevFirst.text_opacity + 0.03 <= 1.0) {
  //             return {
  //               ...prevFirst,
  //               textUPandDown:  document.documentElement.scrollTop >= 5100?ourworkCss.textUPandDown >= 50 ?50:prevFirst.textUPandDown + 1:0,
  //               textUpeerDownHeight:document.documentElement.scrollTop >= 5100?ourworkCss.textUpeerDownHeight <= 0 ?0:prevFirst.textUpeerDownHeight - 1:50,
  //               ImageScroll:
  //               document.documentElement.scrollTop >= 5200?
  //               ourworkCss.ImageScroll >= 700 ?700:
  //               prevFirst.ImageScroll + 3.7
  //               :ourworkCss.ImageScroll
  //               ,
  //             };
  //           // }
  //         } else {
  //           // Check if state needs to be updated
  //           // if (prevFirst.text_opacity - 0.03 >= 0.0) {
  //             return {
  //               ...prevFirst,
  //               textUPandDown: document.documentElement.scrollTop <= 7400 ?ourworkCss.textUPandDown <= 0 ? 0 :prevFirst.textUPandDown - 1:ourworkCss.textUPandDown,
  //               textUpeerDownHeight: document.documentElement.scrollTop <= 7400 ?ourworkCss.textUpeerDownHeight >= 50?50: prevFirst.textUpeerDownHeight + 1:ourworkCss.textUpeerDownHeight,
  //               ImageScroll:document.documentElement.scrollTop <= 7800? prevFirst.ImageScroll - 3.7:ourworkCss.ImageScroll,
  //             };
  //           // }
  //         }
  //         // If state doesn't need to be updated, return the previous state
  //         // return prevFirst;
  //       });
  // }, [scrollY])
  // console.log("first state",ourworkCss.textUpeerDownHeight)
  return (
    <div>
      <div className="window-animation">
        <div
          // data-w-id="3b27adfa-b9d8-d6ac-45b1-ff06a4bd51f3"
          className="window-animation-track"
        >
          <div className="window-animation-window-upper">
            <div className="window-animation-heading">
              <div
                className="heading-text center split-left"
                style={{
                  willChange: "transform",
                  transform: `translate3d(-${ourworkCss.textUPandDown}%, -${ourworkCss.textUPandDown}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                  transformStyle: "preserve-3d",
                }}
              >
                Our
              </div>
              <div className="divider"></div>
              <div
                className="heading-text center split-right"
                style={{
                  willChange: "transform",
                  transform: `translate3d(${ourworkCss.textUPandDown}%, ${ourworkCss.textUPandDown}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                  transformStyle: "preserve-3d",
                }}
              >
                Work
              </div>
            </div>
            <div className="window-animation-window">
              <div
                className="window-animation-curtain upper"
                style={{
                  willChange: "width , height",
                  height: `${
                    ourworkCss.textUpeerDownHeight
                      ? ourworkCss.textUpeerDownHeight
                      : ""
                  }%`,
                }}
              ></div>
              <div
                className="window-animation-curtain lower"
                style={{
                  willChange: "width , height",
                  height: `${
                    ourworkCss.textUpeerDownHeight
                      ? ourworkCss.textUpeerDownHeight
                      : ""
                  }%`,
                }}
              ></div>
              <div
                className="window-animation-content"
                style={{
                  willChange: `transform, opacity`,
                  transform: `translate3d(0px, -${ourworkCss.ImageScroll}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                  transformStyle: `preserve-3d`,
                  opacity: 1,
                }}
              >
                <img
                  src={impact_Guru}
                  loading="eager"
                  //   sizes="(max-width: 479px) 70vw, (max-width: 767px) 60vw, (max-width: 991px) 100vw, 60vw"
                  //   srcset="https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9fa4742b8e1f3b6f6_LAYOUT-7-p-500.jpg 500w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9fa4742b8e1f3b6f6_LAYOUT-7-p-800.jpg 800w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9fa4742b8e1f3b6f6_LAYOUT-7-p-1080.jpg 1080w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9fa4742b8e1f3b6f6_LAYOUT-7-p-1600.jpg 1600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9fa4742b8e1f3b6f6_LAYOUT-7-p-2000.jpg 2000w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9fa4742b8e1f3b6f6_LAYOUT-7.jpg 3240w"
                  alt=""
                  className="window-animation-image"
                  height={1728}
                />

                <img
                  src={FIS_01}
                  loading="eager"
                  //   sizes="(max-width: 479px) 70vw, (max-width: 767px) 60vw, (max-width: 991px) 100vw, 60vw"
                  //   srcset="https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044045bead03dffd01d_Frame%20427320116-p-500.jpg 500w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044045bead03dffd01d_Frame%20427320116-p-800.jpg 800w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044045bead03dffd01d_Frame%20427320116-p-1080.jpg 1080w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044045bead03dffd01d_Frame%20427320116-p-1600.jpg 1600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044045bead03dffd01d_Frame%20427320116-p-2000.jpg 2000w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044045bead03dffd01d_Frame%20427320116-p-2600.jpg 2600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044045bead03dffd01d_Frame%20427320116.jpg 3800w"
                  alt=""
                  height={1728}
                  className="window-animation-image"
                />

                <img
                  src={FIS_SOBO_Carousel}
                  loading="eager"
                  //   sizes="(max-width: 479px) 70vw, (max-width: 767px) 60vw, (max-width: 991px) 100vw, 60vw"
                  //   srcset="https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044ba579d6ccec1e3ac_HF%203-p-500.jpg 500w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044ba579d6ccec1e3ac_HF%203-p-800.jpg 800w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044ba579d6ccec1e3ac_HF%203-p-1080.jpg 1080w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044ba579d6ccec1e3ac_HF%203-p-1600.jpg 1600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044ba579d6ccec1e3ac_HF%203-p-2000.jpg 2000w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044ba579d6ccec1e3ac_HF%203-p-2600.jpg 2600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64be4044ba579d6ccec1e3ac_HF%203.jpg 3840w"
                  alt=""
                  height={1728}
                  className="window-animation-image"
                />

                <img
                  src={Home_Page_EO_Website}
                  loading="eager"
                  //   sizes="(max-width: 479px) 70vw, (max-width: 767px) 60vw, (max-width: 991px) 100vw, 60vw"
                  //   srcset="https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd8fa4742b8e1f3b6b8_LAYOUT-8-p-500.jpg 500w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd8fa4742b8e1f3b6b8_LAYOUT-8-p-800.jpg 800w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd8fa4742b8e1f3b6b8_LAYOUT-8-p-1080.jpg 1080w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd8fa4742b8e1f3b6b8_LAYOUT-8-p-1600.jpg 1600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd8fa4742b8e1f3b6b8_LAYOUT-8-p-2000.jpg 2000w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd8fa4742b8e1f3b6b8_LAYOUT-8-p-2600.jpg 2600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd8fa4742b8e1f3b6b8_LAYOUT-8.jpg 3240w"
                  alt=""
                  className="window-animation-image"
                  height={1681}
                  width={"100%"}
                />

                <img
                  src={FIS_Finland_Landing_Page}
                  loading="eager"
                  //   sizes="(max-width: 479px) 70vw, (max-width: 767px) 60vw, (max-width: 991px) 100vw, 60vw"
                  //   srcset="https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-500.jpg 500w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-800.jpg 800w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-1080.jpg 1080w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-1600.jpg 1600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-2000.jpg 2000w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-2600.jpg 2600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13.jpg 3240w"
                  alt=""
                  className="window-animation-image"
                  height={3875}
                />
                <img
                  src={Home_Page_SOBO_Website}
                  loading="eager"
                  //   sizes="(max-width: 479px) 70vw, (max-width: 767px) 60vw, (max-width: 991px) 100vw, 60vw"
                  //   srcset="https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-500.jpg 500w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-800.jpg 800w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-1080.jpg 1080w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-1600.jpg 1600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-2000.jpg 2000w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13-p-2600.jpg 2600w, https://assets-global.website-files.com/64b117fb0e09e8ce5768e4fe/64ba4bd9e3fe0c7e42cfe703_LAYOUT-13.jpg 3240w"
                  alt=""
                  height={6702}
                  className="window-animation-image"
                />
              </div>
            </div>
          </div>
          <div className="content-cta-actions absolute">
            <div className="magnetic-link">
              <a
                href="#hero"
                target="_blank"
                className="contact-cta secondary w-inline-block"
              >
                <div className="magnetic-item">
                  <div className="link-overflow">
                    <div className="link-label">
                      Book a demo<span className="usd-sign"></span>
                    </div>
                    <div className="link-label on-hover">
                      Book a demo<span className="usd-sign"></span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
