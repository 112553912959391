import React, { useEffect } from "react";

export default function LoadingText() {
  useEffect(() => {
    document.querySelector(".loader2_component").style.display = "flex";
    const wrapper = document.querySelector(".loader2_content-wrapper");
    const children = wrapper.children;
    const hideChildrenOneByOne = (index) => {
      // let PrevInd;
      if (index < children.length) {
        // PrevInd = index;
        // console.log("if first == ", index, "==== Prev ===  ", index - 1);
        // Add display: none to the current child
        for (let index = 0; index < children.length; index++) {
          children[index].style.display = "none";
          // const element = array[index];
        }
        children[index].style.display = "block";

        // Delay the next iteration using setTimeout
        setTimeout(() => {
          // Call the function recursively for the next child
          hideChildrenOneByOne(index + 1);
        }, 500); // Adjust the delay time as needed (here, it's 1000 milliseconds or 1 second)
      } else {
        // console.log("else first");
        // document.querySelector(".loader2_component").style.display = "none";
        document.querySelector(".loader2_component").style.display = "none";
      }
    };

    // Start hiding children from the first one (index 0)
    hideChildrenOneByOne(0);
    
  }, []);

  return (
    <div>
      <div
        fs-scrolldisable-element="when-visible"
        // style="display: none;"
        class="loader2_component"
      >
        <div
          // style="opacity: 0;"
          class="loader2_content-wrapper"
        >
          <div
            class="loader2_text is-first"
          >
            Connect for
          </div>
          <div
            class="loader2_text is-second"
          >
            business
          </div>
          <div
            class="loader2_text is-third"
          >
            brainstorming
          </div>
          <div
            class="loader2_text is-fourth"
          >
            brilliance
          </div>
          <div
            class="loader2_text is-fourth"
          >
            banter
          </div>
          <div
            class="loader2_text is-fourth"
          >
            bigger and better
          </div>
        </div>
        <div
          //   style="transform: translate3d(0px, -100%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          class="loader2_background"
        ></div>
      </div>
    </div>
  );
}
