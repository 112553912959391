import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import LoadingText from './Components/LoadingText';
import Home from './Pages/Home';
import Portfolio from './Pages/Portfolio';
import PortfolioDetail from './Pages/PortfolioDetail';

function App() {
  const location = useLocation();

  // Function to determine if the Header should be hidden based on the route
  const shouldHideHeader = () => {
    const hideHeaderRoutes = ['/portfolio/thane', '/portfolio/pune', '/portfolio/sobo', '/portfolio/edelytics'];
    return hideHeaderRoutes.includes(location.pathname);
  };

  return (
    <>
      <LoadingText />
      {!shouldHideHeader() && <Header />}
      <div className="layout">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:project" element={<PortfolioDetail />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
