import React, { useEffect, useState } from "react";
import EdelyticsOnline from "../assets/images/292233391_403138161840938_7000632581379823953_n 1.png"
import Fisimage from "../assets/images/FIS LOGO.png"
import IGF from "../assets/images/IGF Logo-01.png"
import Niyogin from "../assets/images/Niyogin Fintech Limited 4 1 (1).png"
import star_symbol from "../assets/images/star_symbol.svg"
import Marvel from "../assets/images/Marvel.png"

export default function StaticText({ scrollY, lastScrollY }) {
  const [CssChangesData, setCssChangesData] = useState({
    divHeight: 100,
    textWidth: 0,
    starRotate: 0,
  });

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // console.log("scrollY", document.documentElement.scrollTop,"scrollY > ",scrollY , "lastScrollY >",lastScrollY);
  //     // const scrollY = window.scrollY;

  //     setCssChangesData((prevFirst) => {
  //       if (scrollY > lastScrollY) {
  //         return {
  //           ...prevFirst,
  //           divHeight:
  //           document.documentElement.scrollTop < 1900
  //           ? 100
  //           : prevFirst?.divHeight - 2,
  //         };
  //       } else {
  //         return {
  //           ...prevFirst,
  //           divHeight:
  //             // document.documentElement.scrollTop < 2500
  //               // ?
  //               prevFirst.divHeight + 3
  //               // : prevFirst.divHeight + 3,
  //         };
  //       }
  //     });
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    // const handleScroll = () => {
    setCssChangesData((prevFirst) => {
      if (scrollY > lastScrollY) {
        return {
          ...prevFirst,
          divHeight:
            // document.documentElement.scrollTop >= 1900
              // ?
               prevFirst?.divHeight - 2
              // : CssChangesData.divHeight
              ,
          textWidth:
            document.documentElement.scrollTop >= 1500
              ? CssChangesData.textWidth >= 99 ?CssChangesData.textWidth :prevFirst?.textWidth + 1.5
              : CssChangesData.textWidth,
          starRotate:
            document.documentElement.scrollTop <= 2900
              ? 0
              : prevFirst?.starRotate + 10,
        };
      } else {
        return {
          ...prevFirst,
          divHeight:
            document.documentElement.scrollTop <= 2500
              ?prevFirst.divHeight + 3:CssChangesData.divHeight,
          textWidth:
           prevFirst?.textWidth - 1,
          starRotate:
            document.documentElement.scrollTop <= 2900
              ? 0
              : prevFirst?.starRotate - 10,
        };
      }
    });
    // };

    // window.addEventListener("scroll", handleScroll);

    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [scrollY]); // Include scrollY and lastScrollY in the dependency array

  return (
    <>
      <div
        className="rounded-div-wrapper"
        style={{
          willChange: "width , height",
          height: CssChangesData?.divHeight ,
        }}
        
        // style="will-change: width, height; height: 74.628px;"
      >
        <div className="rounded-div is-top">
          <div className="rounded-div-background"></div>
        </div>
      </div>

      <div className="content-section">
        <div className="spacer-xlarge"></div>
        <div className="content-wrapper justify-vertical overflow-hidden">
          <div className="w-layout-grid fill-text-paragraph-grid">
            <div
              id="w-node-_44f840b4-45b9-0227-9252-a50272b8c2dc-4fcaa4ea"
              className="fill-text-paragraph is-centered"
            >
              <div className="fill-text-item center">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    Let’s turn ideas into impact.
                  </div>
                  

                </div>
                <div
                  className="fill-text-hover-row"
                  // style="will-change: width, height; width: 100%;"
                  style={{
                    willChange: CssChangesData.textWidth ?"width , height" : "",
                    width: CssChangesData.textWidth
                      ? CssChangesData.textWidth + "%"
                      : "",
                  }}
                >
                  <div className="fill-text-text-size">
                    Let’s turn ideas into impact.
                  </div>
                  

                </div>
              </div>
              <div className="fill-text-item center">
                <div className="fill-text-row">
                  <div className="fill-text-text-size fill-subtext-text-size">
                                    At SHV, we’re all about turning ideas into game-changing results. We mix creativity with strategy, using the latest tech to make big impacts. From brainstorm to boom, we’re here to shake things up and make a difference that sticks. Let’s take your idea and make it roar!

                  </div>
                  

                </div>
                <div
                  className="fill-text-hover-row"
                  // style="will-change: width, height; width: 100%;"
                  style={{
                    willChange: CssChangesData.textWidth ?"width , height" : "",
                    width: "100%",
                    // width: CssChangesData.textWidth
                    //   ? CssChangesData.textWidth + "%"
                    //   : "",
                  }}
                >
                  <div className="fill-text-text-size fill-subtext-text-size">
                                     At SHV, we’re all about turning ideas into game-changing results. We mix creativity with strategy, using the latest tech to make big impacts. From brainstorm to boom, we’re here to shake things up and make a difference that sticks. Let’s take your idea and make it roar!

                  </div>
                  

                </div>
              </div>
              {/* <div className="fill-text-item right">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    that fuel your bottom line.
                  </div>
                </div>
                <div
                  data-w-id="44f840b4-45b9-0227-9252-a50272b8c2e8"
                  className="fill-text-hover-row"
                  // style="color: rgb(13, 13, 17); will-change: width, height; width: 78.372%;"
                  style={{
                    willChange: "width , height",
                    width: CssChangesData.textWidth
                      ? CssChangesData.textWidth + "%"
                      : "",
                  }}
                >
                  <div className="fill-text-text-size">
                    that fuel your bottom line.
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="spacer-xlarge-copy"></div>
        <div className="content-cta">
          {/* <div className="content-cta-description">
            <p className="main-paragraph">Let’s turn ideas into impact.</p>
          </div> */}
          <div className="content-cta-actions">
            {/* <div
              className="magnetic-link"
              // style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            >
              <a
                href="#"
                target="_blank"
                className="contact-cta w-inline-block"
              >
                <div
                  className="magnetic-item"
                  // style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <div className="link-overflow">
                    <div className="link-label">
                      Write To Us<span className="usd-sign"></span>
                    </div>
                    <div className="link-label on-hover">
                      Write To Us<span className="usd-sign"></span>
                    </div>
                  </div>
                </div>
              </a>
            </div> */}
            <div
              className="magnetic-link"
              // style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            >
              <a href="#pricing" className="contact-cta secondary w-inline-block">
                <div
                  className="magnetic-item"
                  // style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <div className="link-overflow">
                    <div className="link-label">
                      Let’s talk<span className="usd-sign"></span>
                    </div>
                    <div className="link-label on-hover">
                      Let’s talk<span className="usd-sign"></span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="spacer-small"></div>
        <div className="logo-grid">
          <div className="logo-grid-logo-wrap">
            <img
              src={EdelyticsOnline}
              loading="eager"
              alt="EdelyticsOnline"
              className="logo-grid-logo"
            />
          </div>
          <div className="logo-grid-logo-wrap">
            <img
              src={Fisimage}
              loading="eager"
              alt="Fisimage"
              className="logo-grid-logo"
            />
          </div>
          <div className="logo-grid-logo-wrap">
            <img
              src={IGF}
              loading="eager"
              alt="IGF"
              className="logo-grid-logo"
            />
          </div>
          <div className="logo-grid-logo-wrap">
            <img
              src={Niyogin}
              loading="eager"
              alt="Niyogin"
              className="logo-grid-logo"
            />
          </div>
          <div className="logo-grid-logo-wrap">
            <img
              src={Marvel}
              loading="eager"
              alt="Marvel"
              className="logo-grid-logo"
            />
          </div>
         
        </div>

        {/* <div className="spacer-small"></div> */}

        <div className="spacer-small spacer-small-new"></div>
        <div className="content-wrapper justify-vertical">
          <div
            data-w-id="11d241c9-4822-0fd4-f304-84585210b788"
            className="column-text-wrapper"
          >
            <div
              id="w-node-_689a224d-22d8-6331-da79-3ed3c941619d-4fcaa4ea"
              className="column-text-sticky"
            >
              <div className="heading-text">Everything you need, served on a digital platter.</div>

              <div
                className="rotating-symbol-wrap"
                // style=""
              >
                <div
                  className="rotating-symbol"
                  // style="will-change: transform; transform: translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <img
                    src={star_symbol}
                    loading="eager"
                    alt="star_symbol"
                    className="rotating-symbol-image"
                    // style="will-change: transform; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(123.651deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                    style={{
                      willChange: `transform`,
                      transform: `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${CssChangesData.starRotate}deg) skew(0deg, 0deg)`,
                      transformStyle: `preserve-3d`,
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              id="w-node-a9dccee5-ea87-97bc-8dd7-a0a58e8a2749-4fcaa4ea"
              className="column-text"
            >
              <div
                data-w-id="70270387-9eae-c13c-6ff1-7d1998c02b86"
                className="column-text-row animation"
                // style="will-change: opacity; opacity: 0;"
              >
                <div className="column-text-heading">Social Media Management</div>

                <div className="column-text-paragraph">
                <b>Master Your Online Presence</b><br></br>
                Leave the social media strategy to the experts.
                </div>
              </div>

              <div
                data-w-id="70270387-9eae-c13c-6ff1-7d1998c02b86"
                className="column-text-row animation"
                // style="will-change: opacity; opacity: 0;"
              >
                <div className="column-text-heading">Search Engine Optimization (SEO)</div>

                <div className="column-text-paragraph">
                <b>Boost Your Online Visibility</b><br></br>
                Get found where it matters most - the top of search results.
                </div>
              </div>

              <div
                data-w-id="70270387-9eae-c13c-6ff1-7d1998c02b86"
                className="column-text-row animation"
                // style="will-change: opacity; opacity: 0;"
              >
                <div className="column-text-heading">Content Creation</div>

                <div className="column-text-paragraph">
                <b>Storytelling That Sells</b><br></br>
                Engage your audience with compelling content.
                </div>
              </div>

              <div
                data-w-id="70270387-9eae-c13c-6ff1-7d1998c02b86"
                className="column-text-row animation"
                // style="will-change: opacity; opacity: 0;"
              >
                <div className="column-text-heading">Pay-Per-Click Advertising (PPC)</div>

                <div className="column-text-paragraph">
                <b>Instant Online Visibility</b><br></br>
                Drive traffic and conversions with targeted ads.
                </div>
              </div>

              <div
                data-w-id="70270387-9eae-c13c-6ff1-7d1998c02b86"
                className="column-text-row animation"
                // style="will-change: opacity; opacity: 0;"
              >
                <div className="column-text-heading">Website Design & Development</div>

                <div className="column-text-paragraph">
                <b>Website Design & Development</b><br></br>
                Crafting websites that turn visitors into customers.
                </div>
              </div>


            </div>
          </div>
        </div>

        <div className="spacer-large"></div>

        <div className="content-wrapper align-center">
          <div className="text-spacer">
            <div className="column-text-heading transparency-50">In summary..</div>
            <div className="w-layout-grid fill-text-paragraph-grid">
              <div
                id="w-node-f8fa80f9-bdc6-9883-e39d-da10e222dfa4-4fcaa4ea"
                className="fill-text-paragraph is-centered"
              >
                <div className="fill-text-item center">
                  <div className="fill-text-row">
                    <div className="fill-text-text-size">A dedicated team at</div>
                  </div>
                  <div className="fill-text-hover-row" 
                  // style={{background:"red"}}
                  >
                    <div className="fill-text-text-size">A dedicated team at</div>
                  </div>
                </div>
                <div className="fill-text-item center">
                  <div className="fill-text-row">
                    <div className="fill-text-text-size">your disposal.</div>
                  </div>
                  <div className="fill-text-hover-row" 
                  // style={{background:"red"}}
                  >
                    <div className="fill-text-text-size">your disposal.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
      <div className="spacer-xlarge"></div>
    </>
  );
}
