import React, { useEffect } from "react";

export default function DesignService() {
  useEffect(() => {
    const wrapper = document.querySelector(".text-with-two-lines");
    const children = wrapper.children;
    const hideChildrenOneByOne = (index) => {
      let PrevInd;
      if (index < children.length) {
        PrevInd = index;
        // console.log("if first == ", index, "==== Prev ===  ", index - 1);
        // Add display: none to the current child
        for (let index = 0; index < children.length; index++) {
          children[index].style.display = "none";
          // const element = array[index];
        }
        children[index].style.display = "flex";

        // Delay the next iteration using setTimeout
        setTimeout(() => {
          // Call the function recursively for the next child
          hideChildrenOneByOne(index + 1);
        }, 1500); // Adjust the delay time as needed (here, it's 1000 milliseconds or 1 second)
      } else {
        // console.log("else first");
        hideChildrenOneByOne(0);

        // document.querySelector(".loader2_component").style.display = "none";
        // document.querySelector(".loader2_component").style.display = "none";
      }
    };

    // Start hiding children from the first one (index 0)
    hideChildrenOneByOne(0);

    //   return () => {
    //     second
    //   }
  }, []);

  return (
    <div>
      <div className="listing">
        {/* <div className="spacer-large"></div> */}
        <div className="content-wrapper justify-vertical overflow-hidden">
          <div className="w-layout-grid fill-text-paragraph-grid">
            <div
              id="w-node-f8d62a26-3b3d-8801-bfc0-f3070ae51d7c-4fcaa4ea"
              className="fill-text-paragraph is-centered text-with-two-lines"
            >
              <div className="fill-text-item left">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    We’re not just data-driven.
                  </div>
                </div>
                <div className="fill-text-hover-row">
                  <div className="fill-text-text-size">
                    We’re not just data-driven.
                  </div>
                </div>
              </div>

              <div className="fill-text-item left">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    We’re driven to make your brand unforgettable!
                  </div>
                </div>
                <div className="fill-text-hover-row">
                  <div className="fill-text-text-size">
                    We’re driven to make your brand unforgettable!
                  </div>
                </div>
              </div>

              <div className="fill-text-item left">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">Life is short. Your marketing strategy shouldn’t be!</div>
                </div>
                <div className="fill-text-hover-row">
                  <div className="fill-text-text-size">Life is short. Your marketing strategy shouldn’t be!</div>
                </div>
              </div>

              {/* <div className="fill-text-item left">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    Your marketing strategy shouldn’t be!
                  </div>
                </div>
                <div className="fill-text-hover-row">
                  <div className="fill-text-text-size">
                    Your marketing strategy shouldn’t be!
                  </div>
                </div>
              </div> */}

              <div className="fill-text-item left">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    Your brand’s story begins with an idea.
                  </div>
                </div>
                <div className="fill-text-hover-row">
                  <div className="fill-text-text-size">
                    Your brand’s story begins with an idea.
                  </div>
                </div>
              </div>

              <div className="fill-text-item left">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    Let’s turn it into a blockbuster.
                  </div>
                </div>
                <div className="fill-text-hover-row">
                  <div className="fill-text-text-size">
                    Let’s turn it into a blockbuster.
                  </div>
                </div>
              </div>

              <div className="fill-text-item left">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    Because a change in perspective is the first step to
                    revolution.
                  </div>
                </div>
                <div className="fill-text-hover-row">
                  <div className="fill-text-text-size">
                    Because a change in perspective is the first step to
                    revolution.
                  </div>
                </div>
              </div>

              <div className="fill-text-item left">
                <div className="fill-text-row">
                  <div className="fill-text-text-size">
                    Your brand’s story deserves a collaborative plot.
                  </div>
                </div>
                <div className="fill-text-hover-row">
                  <div className="fill-text-text-size">
                    Your brand’s story deserves a collaborative plot.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          {/* <div className="listing-column">
            <div className="listing-row">
              <div
                id="w-node-a57d313c-3772-9029-5021-df95395ee461-4fcaa4ea"
                className="listing-header"
              >
                Brand
              </div>
              <div className="magnetic-link">
                <a href="#pricing" className="contact-cta secondary w-inline-block">
                  <div className="magnetic-item">
                    <div className="link-overflow">
                      <div className="link-label">
                        View Pricing<span className="usd-sign"></span>
                      </div>
                      <div className="link-label on-hover">
                        View Pricing<span className="usd-sign"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="listing-row">
              <div
                id="w-node-fb76784c-b57f-89a9-d09e-0ae9a251c86c-4fcaa4ea"
                className="hide-tablet"
              ></div>
              <div
                id="w-node-fb76784c-b57f-89a9-d09e-0ae9a251c86d-4fcaa4ea"
                className="column-text-row"
              >
                <div className="column-text-heading">
                  1-2 months average / 8 design sprints
                </div>
                <div className="column-text-list">
                  <div className="column-text-paragraph">Logo design</div>
                  <div className="column-text-paragraph">Visual Identity</div>
                  <div className="column-text-paragraph">Collateral</div>
                  <div className="column-text-paragraph">Brand Guidelines</div>
                  <div className="column-text-paragraph">Animation</div>
                  <div className="column-text-paragraph">Naming</div>
                </div>
              </div>
            </div>
            <div className="listing-row">
              <div
                id="w-node-bc8a8585-7ba5-21f6-7687-e2d2e1bbc1b9-4fcaa4ea"
                className="listing-header"
              >
                Product
              </div>
              <div className="magnetic-link">
                <a href="#pricing" className="contact-cta secondary w-inline-block">
                  <div className="magnetic-item">
                    <div className="link-overflow">
                      <div className="link-label">
                        View Pricing<span className="usd-sign"></span>
                      </div>
                      <div className="link-label on-hover">
                        View Pricing<span className="usd-sign"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="listing-row">
              <div
                id="w-node-_9fa3b087-cc04-7ce5-f92d-2ad4aae448fc-4fcaa4ea"
                className="hide-tablet"
              ></div>
              <div
                id="w-node-_9fa3b087-cc04-7ce5-f92d-2ad4aae448fd-4fcaa4ea"
                className="column-text-row"
              >
                <div className="column-text-heading">
                  2-3 months average / 12 design sprints
                </div>
                <div className="column-text-list">
                  <div className="column-text-paragraph">Mobile/Web apps</div>
                  <div className="column-text-paragraph">Design systems</div>
                  <div className="column-text-paragraph">Wireframing</div>
                  <div className="column-text-paragraph">Interaction design</div>
                  <div className="column-text-paragraph">UX design</div>
                  <div className="column-text-paragraph">Design thinking</div>
                </div>
              </div>
            </div>
            <div className="listing-row">
              <div
                id="w-node-_83c61fe6-e13d-fcc4-a85c-5d4ed74dea3d-4fcaa4ea"
                className="listing-header"
              >
                Web
              </div>
              <div className="magnetic-link">
                <a href="#pricing" className="contact-cta secondary w-inline-block">
                  <div className="magnetic-item">
                    <div className="link-overflow">
                      <div className="link-label">
                        View Pricing<span className="usd-sign"></span>
                      </div>
                      <div className="link-label on-hover">
                        View Pricing<span className="usd-sign"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="listing-row">
              <div
                id="w-node-_9ef67837-66e2-68b2-c314-8c3c2a69a201-4fcaa4ea"
                className="hide-tablet"
              ></div>
              <div
                id="w-node-_9ef67837-66e2-68b2-c314-8c3c2a69a202-4fcaa4ea"
                className="column-text-row"
              >
                <div className="column-text-heading">
                  1-2 months average / 8 design sprints
                </div>
                <div className="column-text-list">
                  <div className="column-text-paragraph">Art direction</div>
                  <div className="column-text-paragraph">Wireframing</div>
                  <div className="column-text-paragraph">Responsive design</div>
                  <div className="column-text-paragraph">Prototyping</div>
                  <div className="column-text-paragraph">E-commerce</div>
                  <div className="column-text-paragraph">Webflow development</div>
                </div>
              </div>
            </div>
            <div className="listing-row">
              <div
                id="w-node-_1f00bea0-80d2-7cda-9cdf-0415970f540d-4fcaa4ea"
                className="listing-header"
              >
                Marketing
              </div>
              <div className="magnetic-link">
                <a href="#pricing" className="contact-cta secondary w-inline-block">
                  <div className="magnetic-item">
                    <div className="link-overflow">
                      <div className="link-label">
                        View Pricing<span className="usd-sign"></span>
                      </div>
                      <div className="link-label on-hover">
                        View Pricing<span className="usd-sign"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="listing-row last">
              <div
                id="w-node-a0a3d31d-f0ed-3597-11d7-83f956eead54-4fcaa4ea"
                className="hide-tablet"
              ></div>
              <div
                id="w-node-_99b0ca4d-6c19-3179-5a66-57d65c62cca2-4fcaa4ea"
                className="column-text-row"
              >
                <div className="column-text-heading">
                  1-2 weeks average / 2 design sprints
                </div>
                <div className="column-text-list">
                  <div className="column-text-paragraph">Pitch deck design</div>
                  <div className="column-text-paragraph">Social media</div>
                  <div className="column-text-paragraph">Email design</div>
                  <div className="column-text-paragraph">Tradeshow assets</div>
                  <div className="column-text-paragraph">Brochures</div>
                  <div className="column-text-paragraph">Signage</div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="spacer-large"></div> */}
      </div>
    </div>
  );
}
