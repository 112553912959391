import React, { useEffect, useState } from "react";

export default function PricingTag() {
  const [classStyle, setclassStyle] = useState(false);

  useEffect(() => {}, []);

  const btnClick = () => {
    setclassStyle(!classStyle);
  };
  return (
    <div>
      <div id="pricing" className="pricing">
        <div className="content-wrapper">
          {/* <div className="pricing-header">
            <div className="text-small">Pricing</div>
          </div> */}
          <div className="pricing-content">
            <div
              id="w-node-_95400f24-02e2-9c99-f688-53129d596774-4fcaa4ea"
              className="pricing-content-price"
            >
              <div className="xlarge-heading-text">We are SHV</div>
              {/* <div className="column-text-heading transparency-50 special">
                per month
              </div> */}
            </div>
            <div
              id="w-node-_15f6742d-f643-85bc-611b-7fd74ff5cf52-4fcaa4ea"
              className="pricing-content-description"
            >
              <div className="pricing-content-points">
                <div className="column-text-paragraph text-color-black">
                  Your trusty Mar-Tech partner based in the heart of bustling
                  Mumbai.
                </div>
                {/* <div className="column-text-paragraph text-color-black">
                  Scalable design solution
                </div> */}
              </div>
              <div className={`text-small margin-bottom spaceadd_p `}>
                <p>
                  We're not your typical tech folks; we're the friendly faces
                  you turn to when you want to conquer the digital maze without
                  the headaches.
                </p>

                <p
                  style={{
                    height: classStyle ? "auto" : "0",
                    padding: classStyle ? "10px 0" : "0",
                    overflow: classStyle ? "" : "hidden",
                  }}
                >
                  So, what's our game? Imagine a crew of digital enthusiasts
                  who've cracked the code on making marketing and tech actually
                  make sense. No more drowning in jargon – we're here to
                  simplify the digital puzzle.
                </p>

                <p
                  style={{
                    height: classStyle ? "auto" : "0",
                    padding: classStyle ? "10px 0" : "0",
                    overflow: classStyle ? "" : "hidden",
                  }}
                >
                  Our leaders? They're like your trusty tour guides, leading you
                  through the jungle of digital possibilities without the fancy
                  tech talk.
                </p>

                <p
                  style={{
                    height: classStyle ? "auto" : "0",
                    padding: classStyle ? "10px 0" : "0",
                    overflow: classStyle ? "" : "hidden",
                  }}
                >
                  But here's the kicker – we're not just rookies on the block.
                  SHV teams up with Vitoba Technologies Pvt Ltd, a name with
                  a track record dating back to 2015. Why's this a plus for you?
                  You get the agility of a startup with the wisdom of seasoned
                  pros.
                </p>

                <p
                  style={{
                    height: classStyle ? "auto" : "0",
                    padding: classStyle ? "10px 0" : "0",
                    overflow: classStyle ? "" : "hidden",
                  }}
                >
                  Our battle cry? "Get Stuff Done!" We're all about cutting
                  through the digital noise and delivering real, tangible
                  results that make a difference for your business.
                </p>

                <p
                  style={{
                    height: classStyle ? "auto" : "0",
                    padding: classStyle ? "10px 0" : "0",
                    overflow: classStyle ? "" : "hidden",
                  }}
                >
                  So, if you've ever felt lost in the digital wilderness, unsure
                  how to make marketing and tech play nice together, we've got
                  your back. Let's untangle the web and chart a course for
                  digital success that actually works.
                </p>
              </div>
              <div className="content-cta-actions">
                <div className="magnetic-link" onClick={btnClick}>
                  <a
                    href="#demo"
                    // target="_blank"
                    className="contact-cta large w-inline-block"
                  >
                    <div className="magnetic-item">
                      <div className="link-overflow">
                        <div className="link-label ">
                          {classStyle?"Read Less":"Read More"}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
