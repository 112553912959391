import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@mui/icons-material/Call";

export default function Footer() {
  return (
    <div className="Footer_main">
      <div className="Footer_container">
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d965565.8134446096!2d72.837355!3d19.031111!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa5b154b44586a64b%3A0xbba3c6e1fb6345c2!2sSHV%20360!5e0!3m2!1sen!2sin!4v1707491515435!5m2!1sen!2sin"  style={{border:"0",width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        <div className="Footer_div">
          <h2>Address</h2>

          <a href="https://maps.app.goo.gl/RSzmw8szgZjmcjBf8" target="_blank" rel="noreferrer noopener">
            <div className="footer_flex"
            //   style={{
            //     display: "flex",
            //     margin: "20px 0",
            //     gap: "10px",
            //     alignItems: "flex-start",
            //   }}
            >
              <RoomIcon />
              <p style={{ margin: "0" }}>
                Shivaji Park, Dadar <br></br>Mumbai, India
              </p>
            </div>
          </a>
        </div>

        <div className="Footer_div">
          <h2>Contact</h2>

          <a href="tel:970-301-4325">
            <div className="footer_flex"
            //   style={{
            //     display: "flex",
            //     margin: "20px 0",
            //     gap: "10px",
            //     alignItems: "center",
            //   }}
            >
              <CallIcon />
              <p style={{ margin: "0" }}>+91 81694 75291</p>
            </div>
          </a>

          <a href="mailto:info@shvmedia.com" target="_blank" rel="noreferrer noopener">
            <div className="footer_flex"
            //   style={{
            //     display: "flex",
            //     margin: "20px 0",
            //     gap: "10px",
            //     alignItems: "center",
            //   }}
            >
              <EmailIcon />
              <p style={{ margin: "0" }}>info@shvmedia.com</p>
            </div>
          </a>
        </div>

        <div className="Footer_div">
          <h2>Follow Us</h2>
          <p className="footer_social">
            <a href="https://www.instagram.com/shv_360/"  target="_blank" rel="noreferrer noopener">
              <InstagramIcon />
            </a>

            <a href="https://www.facebook.com/1shv360" target="_blank" rel="noreferrer noopener">
              <FacebookIcon />
            </a>

            <a href="https://in.linkedin.com/company/shv-360" target="_blank" rel="noreferrer noopener">
              <LinkedInIcon />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
