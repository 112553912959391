import React, { useEffect, useState } from "react";
import HomeBanner from "../Components/HomeBanner";
import StaticText from "../Components/StaticText";
import DesignService from "../Components/DesignService";
import OurWork from "../Components/OurWork";
import PricingTag from "../Components/PricingTag";
import TeamSection from "../Components/TeamSection";
import Footer from "../Components/Footer";
// import FinalText from "../Components/FinalText";

export default function Home() {
  const [scrollData, setScrollData] = useState({
    lastScrollY: window.scrollY,
    scrollY: window.scrollY,
  });

  window.addEventListener("load", (event) => {
    setTimeout(() => {
      window.scroll(0,0)
    }, 500);

  });
  useEffect(() => {
    function handleScroll2() {
      const scrollYdata = window.scrollY;
  
      setScrollData(prevScrollData => ({
        ...prevScrollData,
        scrollY: scrollYdata,
        lastScrollY: prevScrollData.scrollY,
      }));
    }
    // const div = document.querySelector('div.content-section')
  // const rect = div.getBoundingClientRect();
  // console.log("rect.top ==== >>",rect.top, rect.right, rect.bottom, rect.left);

    // let lastScrollY2 = window.scrollY;
  
    window.addEventListener("scroll", handleScroll2);

    return () => {
      window.removeEventListener("scroll", handleScroll2);
    };

  }, [scrollData.scrollY]);  // Removed the unnecessary dependency on 'third'

  return (
    <div className="content">
      <HomeBanner
        scrollY={scrollData.scrollY}
        lastScrollY={scrollData.lastScrollY}
      />
      <StaticText
        scrollY={scrollData.scrollY}
        lastScrollY={scrollData.lastScrollY}
      />
      <DesignService scrollY={scrollData.scrollY}
        lastScrollY={scrollData.lastScrollY} 
        />
        <OurWork scrollY={scrollData.scrollY}
        lastScrollY={scrollData.lastScrollY} 
        />
        <PricingTag scrollY={scrollData.scrollY}
        lastScrollY={scrollData.lastScrollY} 
        />
        {/* <FinalText scrollY={scrollData.scrollY}
        lastScrollY={scrollData.lastScrollY} 
        /> */}
        <TeamSection/>
        <Footer/>
    </div>
  );
}
