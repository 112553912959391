import React, { useState, useEffect } from "react";
import "../style/bandbook.css";
import "../style/home.css";
import img1 from "../assets/images/SHV Website - Creative-07.webp";
import img2 from "../assets/images/SHV Website - Creative-18.webp";
import img3 from "../assets/images/WhatsApp Image 2024-01-16 at 5.37.06 PM (1).jpeg";
import img4 from "../assets/images/SHV Website - Creative-18.webp";
import img5 from "../assets/images/SHV Website - Creative-17.webp";
import img6 from "../assets/images/SHV Website - Creative-08.webp";
import img7 from "../assets/images/SHV Website - Creative-14.webp";
import img8 from "../assets/images/SHV Website - Creative-20.webp";
import img9 from "../assets/images/SHV Website - Creative-13.webp";
import img10 from "../assets/images/SHV Website - Creative-11.webp";
import img11 from "../assets/images/SHV Website - Creative-12.webp";
import img12 from "../assets/images/SHV Website - Creative-12.webp";
import shV_Logo from "../assets/images/shvlogo.png";

export default function HomeBanner({ scrollY, lastScrollY }) {
  const css_data = { image1_css: 4.0, image2_css: 6.0, image3_css: 8.0 };

  const [first, setFirst] = useState({
    text_opacity: 0.0,
    main: 0.0,
    main_zoom: 1.0,
    main_opacity: 1.0,
    imgsc1: css_data.image1_css,
    imgsc2: css_data.image2_css,
    imgsc3: css_data.image3_css,
  });

  useEffect(() => {
    setFirst((prevFirst) => {
      if (scrollY > lastScrollY) {
        // Check if state needs to be updated
        // if (prevFirst.text_opacity + 0.03 <= 1.0) {
        return {
          ...prevFirst,
          main: window.scrollY / 90,
          text_opacity: prevFirst.text_opacity + 0.03,
          main_opacity: prevFirst.main_opacity - 0.01,
          main_zoom: prevFirst.main_zoom - 0.003,
          imgsc1: prevFirst.imgsc1 + 0.01,
          imgsc2: prevFirst.imgsc2 + 0.01,
          imgsc3: prevFirst.imgsc3 + 0.01,
        };
        // }
      } else {
        // Check if state needs to be updated
        // if (prevFirst.text_opacity - 0.03 >= 0.0) {
        return {
          ...prevFirst,
          main: window.scrollY / 90,
          text_opacity:
            document.documentElement.scrollTop <= 100
              ? 0
              : prevFirst.text_opacity - 0.03,
          main_opacity:
            document.documentElement.scrollTop <= 100
              ? 1
              : prevFirst.main_opacity + 0.01,
          main_zoom:
            document.documentElement.scrollTop <= 100
              ? 1
              : prevFirst.main_zoom + 0.003,
          imgsc1:
            document.documentElement.scrollTop <= 100
              ? 4.0
              : prevFirst.imgsc1 - 0.01,
          imgsc2:
            document.documentElement.scrollTop <= 100
              ? 6.0
              : prevFirst.imgsc2 - 0.01,
          imgsc3:
            document.documentElement.scrollTop <= 100
              ? 8.0
              : prevFirst.imgsc3 - 0.01,
        };
        // }
      }
      // If state doesn't need to be updated, return the previous state
      // return prevFirst;
    });
  }, [scrollY]);
  // console.log("scrollY  ====== >> ", scrollY);

  return (
    <div id="hero" className="archive-holder">
      <div className="archive-section">
        <div className="scroll-down-wrapper">
          <a href="/#" className="scroll-down-link w-inline-block">
            <div>Scroll down</div>
            <div className="scroll-down-indicator">
              <div
                className="scroll-down-progress"
                // style="will-change: transform; transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
              ></div>
            </div>
          </a>
        </div>
        <div
          className="archive-wrapper"
          style={{
            transform: `translate3d(0px, 0px, 0px) scale3d(${first.main_zoom}, ${first.main_zoom}, 1) rotateX(0deg) rotateY(0deg) rotateZ(-${first.main}deg) skew(0deg, 0deg)`,
            opacity: `${first.main_opacity}`,
          }}
        >
          <div
            className="_3rd"
            style={{
              transform: ` translate3d(0px, -${first.imgsc1}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            }}
          >
            <div className="archive-image-column">
              <div className="archive-image-wrapper">
                <img
                  src={img1}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img2}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img3}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
             
              <div className="archive-image-wrapper">
                <img
                  src={img5}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img6}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
            </div>
          </div>
          <div
            className="_1st"
            style={{
              transform: ` translate3d(0px, -${first.imgsc1}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            }}
          >
            <div className="archive-image-column">
              <div className="archive-image-wrapper">
                <img
                  src={img7}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img8}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img9}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img6}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img10}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img11}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
            </div>
          </div>
          <div
            className="_2nd"
            style={{
              transform: ` translate3d(0px, -${first.imgsc1}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            }}
          >
            <div className="archive-image-column">
              <div className="archive-image-wrapper">
                <img
                  src={img12}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>

              {/* <div className="archive-image-wrapper">
                <img
                  src={img2}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div> */}
              <div className="archive-image-wrapper">
                <img
                  src={img3}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img4}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img1}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img5}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
            </div>
          </div>
          <div
            className="_3rd"
            style={{
              transform: ` translate3d(0px, -${first.imgsc1}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            }}
          >
            <div className="archive-image-column">
              <div className="archive-image-wrapper">
                <img
                  src={img6}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>

              <div className="archive-image-wrapper">
                <img
                  src={img9}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img10}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img11}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img7}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img8}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
            </div>
          </div>
          <div
            className="_1st"
            style={{
              transform: ` translate3d(0px, -${first.imgsc1}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
            }}
          >
            <div className="archive-image-column">
              <div className="archive-image-wrapper">
                <img
                  src={img12}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img1}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img2}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img3}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img4}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
              <div className="archive-image-wrapper">
                <img
                  src={img5}
                  loading="eager"
                  alt=""
                  className="image-background"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="archive-title-wrapper">
          <div
            className="archive-title"
            style={{ opacity: first.text_opacity }}
            // style="will-change: font-variation-settings, opacity; font-variation-settings: &quot;wght&quot; 450; opacity: 1;"
          >
            <img src={shV_Logo} alt="SHV_logo" className="logo_big_image" />
          </div>
        </div>
      </div>
      <div
        data-w-id="2a9c2d19-231f-f721-8976-101822b09ee8"
        className="archive-trigger"
      ></div>
    </div>
  );
}
