import React from "react";
import SHVlogo from  "../assets/images/shvlogo.png"

export default function Header() {
  return (
    <div>
      <div className="navigation-wrap">
        <div className="navigation">
          <div className="brand-wrapper">
            <div
              className="magnetic-link"
              //style="will-change: transform; transform: translate3d(-0.0006%, 0.0012%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            >
              <a
                href="#hero"
                id="w-node-_8ae57029-6c9a-477c-cd90-cde36d328f1e-6d328f1c"
                className="brand-link move w-inline-block"
              >
                <div className="brand-size-style">
                  <div className="link-overflow">
                    <div
                      className="link-label brand-label"
                      //style="transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                    >
                     {/* <span style={{color: "rgb(185, 18, 132)"}}>S</span><span style={{color: "rgb(255, 191, 16)"}}>H</span><span style={{color: "rgb(128, 217, 207)"}}>V</span> */}
                     <img src={SHVlogo} alt="SHVLOGO" style={{width: "60%"}}/>
                    </div>
                    <div
                      className="link-label on-hover brand-label"
                      //style="transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                    >
                     {/* <span style={{color: "rgb(185, 18, 132)"}}>S</span><span style={{color: "rgb(255, 191, 16)"}}>H</span><span style={{color: "rgb(128, 217, 207)"}}>V</span> */}
                     <img src={SHVlogo} alt="SHVLOGO" style={{width: "60%"}}/>

                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div
            data-collapse="all"
            data-animation="default"
            data-duration="0"
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            className="navbar w-nav"
          >
            {/* <div
              id="w-node-_8ae57029-6c9a-477c-cd90-cde36d328f24-6d328f1c"
              className="navbar-controls display-none"
            >
              <nav
                role="navigation"
                className="navbar-menu w-nav-menu"
                //style="display: none; transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
              >
                <div className="navbar-wrapper" //style="display: none;"
                >
                  <div className="navbar-rounded-fixed">
                    <div
                      className="navbar-rounded-wrapper"
                      //style="width: 100px; height: 100%;"
                    >
                      <div className="navbar-rounded-div"></div>
                    </div>
                  </div>
                  <div className="w-layout-grid navbar-menu-grid">
                    <div className="w-layout-grid navbar-menu-column">
                      <div className="navbar-menu-font-style">
                        <a
                          href="/"
                          aria-current="page"
                          className="navbar-menu-item-wrapper w-inline-block w--current"
                        >
                          <div
                            className="navbar-menu-item"
                            ////style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                          >
                            <div className="navbar-menu-link">Home</div>
                          </div>
                          <div
                            className="navbar-menu-background"
                            ////style="height: 0%;"
                          ></div>
                          <div className="navbar-menu-scrolling-wrapper">
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">Home</div>
                              <div className="navbar-menu-link">Home</div>
                              <div className="navbar-menu-link">Home</div>
                            </div>
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">Home</div>
                              <div className="navbar-menu-link">Home</div>
                              <div className="navbar-menu-link">Home</div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="navbar-menu-font-style">
                        <a
                          href="#"
                          className="navbar-menu-item-wrapper w-inline-block"
                        >
                          <div
                            className="navbar-menu-item"
                            //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                          >
                            <div className="navbar-menu-link">About</div>
                          </div>
                          <div
                            className="navbar-menu-background"
                            //style="height: 0%;"
                          ></div>
                          <div className="navbar-menu-scrolling-wrapper">
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">About</div>
                              <div className="navbar-menu-link">About</div>
                              <div className="navbar-menu-link">About</div>
                            </div>
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">About</div>
                              <div className="navbar-menu-link">About</div>
                              <div className="navbar-menu-link">About</div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="navbar-menu-font-style">
                        <a
                          href="#"
                          className="navbar-menu-item-wrapper w-inline-block"
                        >
                          <div
                            className="navbar-menu-item"
                            //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                          >
                            <div className="navbar-menu-link">Work</div>
                          </div>
                          <div
                            className="navbar-menu-background"
                            //style="height: 0%;"
                          ></div>
                          <div className="navbar-menu-scrolling-wrapper">
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">Work</div>
                              <div className="navbar-menu-link">Work</div>
                              <div className="navbar-menu-link">Work</div>
                            </div>
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">Work</div>
                              <div className="navbar-menu-link">Work</div>
                              <div className="navbar-menu-link">Work</div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="navbar-menu-font-style">
                        <a
                          href="#"
                          className="navbar-menu-item-wrapper w-inline-block"
                        >
                          <div
                            className="navbar-menu-item"
                            //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                          >
                            <div className="navbar-menu-link">Notes</div>
                          </div>
                          <div
                            className="navbar-menu-background"
                            //style="height: 0%;"
                          ></div>
                          <div className="navbar-menu-scrolling-wrapper">
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">Notes</div>
                              <div className="navbar-menu-link">Notes</div>
                              <div className="navbar-menu-link">Notes</div>
                            </div>
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">Notes</div>
                              <div className="navbar-menu-link">Notes</div>
                              <div className="navbar-menu-link">Notes</div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="navbar-menu-font-style">
                        <a
                          href="#"
                          className="navbar-menu-item-wrapper w-inline-block"
                        >
                          <div
                            className="navbar-menu-item"
                            //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                          >
                            <div className="navbar-menu-link">Contact</div>
                          </div>
                          <div
                            className="navbar-menu-background"
                            //style="height: 0%;"
                          ></div>
                          <div className="navbar-menu-scrolling-wrapper">
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">Contact</div>
                              <div className="navbar-menu-link">Contact</div>
                              <div className="navbar-menu-link">Contact</div>
                            </div>
                            <div
                              className="navbar-menu-scrolling"
                              //style="opacity: 0;"
                            >
                              <div className="navbar-menu-link">Contact</div>
                              <div className="navbar-menu-link">Contact</div>
                              <div className="navbar-menu-link">Contact</div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="w-layout-grid navbar-meta">
                      <div className="w-layout-grid navbar-social-grid">
                        <div
                          className="magnetic-link"
                          //style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                        >
                          <a href="#" className="link-block w-inline-block">
                            <div
                              className="magnetic-item"
                              //style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                            >
                              <div className="link-overflow">
                                <div
                                  className="navbar-menu-item"
                                  //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                                >
                                  <div className="link-label">Instagram</div>
                                </div>
                                <div className="link-label on-hover">Instagram</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="overflow-hidden">
                          <div
                            className="navbar-menu-item"
                            //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                          >
                            &nbsp;•&nbsp;
                          </div>
                        </div>
                        <div
                          className="magnetic-link"
                          //style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                        >
                          <a href="#" className="link-block w-inline-block">
                            <div
                              className="magnetic-item"
                              //style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                            >
                              <div className="link-overflow">
                                <div
                                  className="navbar-menu-item"
                                  //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                                >
                                  <div className="link-label">Twitter</div>
                                </div>
                                <div className="link-label on-hover">Twitter</div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="overflow-hidden">
                          <div
                            className="navbar-menu-item"
                            //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                          >
                            &nbsp;•&nbsp;
                          </div>
                        </div>
                        <div
                          className="magnetic-link"
                          //style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                        >
                          <a href="#" className="link-block w-inline-block">
                            <div
                              className="magnetic-item"
                              //style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                            >
                              <div className="link-overflow">
                                <div
                                  className="navbar-menu-item"
                                  //style="transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                                >
                                  <div className="link-label">Dribbble</div>
                                </div>
                                <div className="link-label on-hover">Dribbble</div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
              <div
                data-w-id="8ae57029-6c9a-477c-cd90-cde36d328f83"
                className="menu-button w-nav-button"
                //style="-webkit-user-select: text;"
                aria-label="menu"
                role="button"
                tabindex="0"
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
              >
                <div
                  className="menu-button-wrapper"
                  //style="will-change: transform; transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <div className="close-top"></div>
                  <div className="close-bottom"></div>
                </div>
              </div>
            </div> */}
            <div
              className="magnetic-link"
              //style="will-change: transform; transform: translate3d(0.0006%, 0.0012%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            >
              <a
                href="mailto:info@shvmedia.com?subject=Hello"
                className="w-inline-block"
              >
                <div
                  className="magnetic-item text-color-white"
                  //style="will-change: transform; transform: translate3d(0.0012%, 0.0024%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <div className="link-overflow color-white">
                    <div
                      className="link-label"
                      //style="transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                    >
                      info@shvmedia.com
                    </div>
                    <div
                      className="link-label on-hover"
                      //style="transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                    >
                      info@shvmedia.com
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="w-nav-overlay"
              data-wf-ignore=""
              id="w-nav-overlay-0"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
