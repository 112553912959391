import React from "react";
import Ankit from "../assets/images/Ankit.png";
import Shivani from "../assets/images/shivani-test-02.jpeg";
import Sounhkan from "../assets/images/sounakhan-test-01.jpeg";
import Jyoti from "../assets/images/jyoti-test-01.jpeg";
import Mrunali from "../assets/images/mrunali-test-02.jpeg";
import Rishi from "../assets/images/rishi-test-02.jpeg";
import Manashree from "../assets/images/manashree-test-01.jpeg";
// import Sachin from "../assets/images/Sachin.png";
import Prateeksha_Nayak from "../assets/images/prateksha-test-01.jpeg";
import Anita_Mokashi from "../assets/images/anita-test-01.jpeg";
import Ritesh_Bhoite from "../assets/images/ritesh-test-01.jpeg";
// import Anita_Mokashi from "../assets/images/Anita Mokashi .png";
import Shweta_Bhoite from "../assets/images/shweta-test-01.jpeg";
import Rashmin_Kour from "../assets/images/rashmin-test-02.jpeg";
// import Shweta_Bhoite from "../assets/images/Shweta Bhoite.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function TeamSection() {
  return (
    <div className="team_secion">
      <div className="Team_main_title">
        <h1>Our Team</h1>
      </div>
      <div className="team_detail_cont">
        
        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Shweta_Bhoite} alt="Shweta Bhoite" loading="eager"/>
          </div>
          <div style={{textAlign:"center"}}>
          <h1>Shweta Bhoite <br></br>( Founder )</h1>
          <a href="https://www.linkedin.com/in/shwe8/" target="_blank" rel="noopener noreferrer" >
            <LinkedInIcon sx={{color:"white"}}/>
          </a>
          </div>
        </div>
        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Ritesh_Bhoite} alt="Anita Mokashi " loading="eager"/>
          </div>
          <div style={{textAlign:"center"}}>
          <h1>Ritesh Bhoite <br></br>( Founder ) </h1>
          <a href="https://www.linkedin.com/in/anitamokashi/" target="_target" onDragStart={(e) => e.preventDefault()}>
          <LinkedInIcon sx={{color:"white"}}/>
          </a>
          </div>

        </div>
        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Anita_Mokashi} alt="Anita Mokashi " loading="eager" />
          </div>
          <div style={{textAlign:"center"}}>
          <h1>Anita Mokashi <br></br>( Founder ) </h1>
          <a href="https://www.linkedin.com/in/anitamokashi/" target="_target" onDragStart={(e) => e.preventDefault()}>
          <LinkedInIcon sx={{color:"white"}}/>
          </a>
          </div>

        </div>
     
        
     

        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Prateeksha_Nayak} alt="Prateeksha Nayak"  loading="eager"/>
          </div>
          <h1>Prateeksha Nayak</h1>
        </div>

        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Rishi} alt="Rishi Rathod" className="team_emp_img" loading="eager" />
          </div>
          <h1>Rishi Rathod</h1>
        </div>

        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Shivani} alt="Shivani Kesarwani" className="team_emp_img" loading="eager" />
          </div>
          <h1>Shivani Kesarwani</h1>
        </div>
        
       
        
        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Jyoti} alt="Jyoti Pandey"  loading="eager"/>
          </div>
          <h1>Jyoti Pandey</h1>
        </div>
        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Sounhkan} alt="Sounhakan"  loading="eager"/>
          </div>
          <h1>Sounhakan</h1>
        </div>
        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Mrunali} alt="Mrunali Melekar" className="team_emp_img" loading="eager" />
          </div>
          <h1>Mrunali Melekar</h1>
        </div>
        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Manashree} alt="Manashree Rane"  loading="eager"/>
          </div>
          <h1>Manashree Rane</h1>
        </div>
        <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Rashmin_Kour} alt="Rashmin Kour" loading="eager" />
          </div>
          <h1>Rashmin Kour</h1>
        </div>
        
        {/* <div className="team_employee_data">
          <div className="team_emp_profile">
            <img src={Sachin} alt="Sachin Pandey" />
          </div>
          <h1>Sachin Pandey</h1>
        </div>
         */}
        
      </div>
    </div>
  );
}
